import React, { useState, useEffect, useRef } from 'react';
import './Windows.css';
import { en } from './languages/en';
import { fr } from './languages/fr';
import { es } from './languages/es';
import { de } from './languages/de';
import { id } from './languages/id'; // Import Indonesian language file
import { tr } from './languages/tr'; // Import Turkish language file

const languages = {
  en,
  fr,
  es,
  de,
  id, // Add Indonesian language object
  tr // Add Turkish language object
  // Add more language objects as needed
};

const images = [
  'assets/growtopia-banner.webp'
];

const preloadImages = (imageArray) => {
  imageArray.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
};

const LanguageDropdown = ({ onChange }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    onChange(languages[event.target.value]);
  };

  return (
    <div className="language-dropdown">
      <label htmlFor="language-select">Select Language: </label>
      <select id="language-select" value={selectedLanguage} onChange={handleLanguageChange}>
        <option value="en">English</option>
        <option value="fr">French</option>
        <option value="es">Spanish</option>
        <option value="de">German</option>
        <option value="id">Indonesian</option>
        <option value="tr">Turkish</option>
        {/* Add more options as needed */}
      </select>
    </div>
  );
};

const WindowsPage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [language, setLanguage] = useState(en); // Default to English
  const timeoutRef = useRef(null);

  useEffect(() => {
    preloadImages(images);

    const resetTimeout = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 6000);
    };

    resetTimeout();

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [currentImageIndex]);

  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;
    const langCode = userLang.split('-')[0]; // Get the language code (e.g., 'en' from 'en-US')

    if (languages[langCode]) {
      setLanguage(languages[langCode]);
    } else {
      setLanguage(en); // Default to English if language not supported
    }
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert(language.urlCopied);
  };

  return (
    <div className="WindowsPage-container" style={{ backgroundImage: `url(${images[0]})` }}>
      <img src="assets/growtopia-logo.webp" alt="Logo" className="Windows-growtopia-logo" />
      <img src="assets/growtoken.webp" alt="Logo" className="Windows-logo2" />
      <div className="content">
      <div className="video-container">
  <video width="360" height="215" controls>
    <source src="/assets/Windows-tutorial.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>

        <div className="tutorial-container" style={{ position: 'relative' }}>
          <LanguageDropdown onChange={setLanguage} />
          <h2>{language.tutorialTitle}</h2>
          <p>{language.howToDownload}</p>
          <ol>
            {language.steps.slice(0, 7).map((step, index) => (
              <li key={index}>{step}</li>
            ))}
            <li>
              {language.steps[8]}
              <div className="copy-container">
                <span className="copy-text">
                  {language.urlLine1}<br />
                  {language.urlLine2}
                </span>
                <button className="copy-button" onClick={() => copyToClipboard(`${language.urlLine1}\n${language.urlLine2}`)}>
                  {language.copyURL}
                </button>
              </div>
            </li>
            {language.steps.slice(6).map((step, index) => (
              <li key={index + 6}>{step}</li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default WindowsPage;
