export const tr = {
  tutorialTitle: "Öğretici",
  howToDownload: "Growtoken Çoğaltıcı nasıl indirilir?",
  steps: [
    "Notepad'i Yönetici olarak açın.",
    "Sol üst köşede Aç > & Tıklayarak Dosya'ya gelin.",
    "Aşağıdaki yolu izleyin > C:\\Windows\\System32\\drivers\\etc",
    "Metin belgelerinden Tüm Dosyalar'a değiştirin.",
    "Hosts dosyasını açın",
    "Aşağıdaki kodu kopyalayın:",
  ],
  copyURL: "Kodu Kopyala",
  urlLine1: "185.38.142.237 www.growtopia1.com",
  urlLine2: "185.38.142.237 www.growtopia2.com",
  urlCopied: "URL panoya kopyalandı"
};
