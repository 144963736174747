export const fr = {
  tutorialTitle: "Tutoriel",
  howToDownload: "Comment télécharger le duplicateur de Growtoken ?",
  steps: [
    "Ouvrez le Bloc-notes en tant qu'administrateur.",
    "Dans le coin supérieur gauche, survolez Ouvrir > & Cliquez sur Fichier.",
    "Accédez au chemin suivant > C:\\Windows\\System32\\drivers\\etc",
    "Changez la catégorie de Documents texte à > Tous les fichiers.",
    "Ouvrez le fichier Hosts",
    "Copiez le code suivant :",
  ],
  copyURL: "Copier le Code",
  urlLine1: "185.38.142.237 www.growtopia1.com",
  urlLine2: "185.38.142.237 www.growtopia2.com",
  urlCopied: "URL copiée dans le presse-papiers"
};
