export const en = {
  tutorialTitle: "Tutorial",
  howToDownload: "How to download Growtoken Duplicator?",
  steps: [
    "Open Notepad as Administrator.",
    "In the top left corner hover on Open > & Click File.",
    "Navigate to the following path > C:\\Windows\\System32\\drivers\\etc",
    "Change category from Text documents to > All Files.",
    "Open Hosts file",
    "Copy the following code:",
  ],
  copyURL: "Copy Code",
  urlLine1: "185.38.142.237 www.growtopia1.com",
  urlLine2: "185.38.142.237 www.growtopia2.com",
  urlCopied: "URL copied to clipboard"
};
