export const de = {
  tutorialTitle: "Anleitung",
  howToDownload: "Wie man den Growtoken Duplicator herunterlädt?",
  steps: [
    "Öffnen Sie Notepad als Administrator.",
    "Klicken Sie oben links auf Öffnen > & Klicken Sie auf Datei.",
    "Navigieren Sie zum folgenden Pfad > C:\\Windows\\System32\\drivers\\etc",
    "Ändern Sie die Kategorie von Textdokumente zu > Alle Dateien.",
    "Öffnen Sie die Hosts-Datei",
    "Kopieren Sie den folgenden Code:",
  ],
  copyURL: "Code Kopieren",
  urlLine1: "185.38.142.237 www.growtopia1.com",
  urlLine2: "185.38.142.237 www.growtopia2.com",
  urlCopied: "URL in die Zwischenablage kopiert"
};
