export const es = {
  tutorialTitle: "Tutorial",
  howToDownload: "¿Cómo descargar el Duplicador de Growtoken?",
  steps: [
    "Abra el Bloc de notas como Administrador.",
    "En la esquina superior izquierda, vaya a Abrir > y haga clic en Archivo.",
    "Vaya a la siguiente ruta > C:\\Windows\\System32\\drivers\\etc",
    "Cambie la categoría de Documentos de texto a > Todos los archivos.",
    "Abra el archivo Hosts",
    "Copie el siguiente código:",
  ],
  copyURL: "Copiar Código",
  urlLine1: "185.38.142.237 www.growtopia1.com",
  urlLine2: "185.38.142.237 www.growtopia2.com",
  urlCopied: "URL copiada al portapapeles"
};
