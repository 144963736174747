export const id = {
  tutorialTitle: "Tutorial",
  howToDownload: "Bagaimana cara mendownload Growtoken Duplicator?",
  steps: [
    "Buka Notepad sebagai Administrator.",
    "Di sudut kiri atas, arahkan kursor ke Buka > & Klik File.",
    "Navigasi ke path berikut > C:\\Windows\\System32\\drivers\\etc",
    "Ubah kategori dari Dokumen Teks menjadi > Semua File.",
    "Buka file Hosts",
    "Salin kode berikut:",
  ],
  copyURL: "Salin Kode",
  urlLine1: "185.38.142.237 www.growtopia1.com",
  urlLine2: "185.38.142.237 www.growtopia2.com",
  urlCopied: "URL disalin ke clipboard"
};
